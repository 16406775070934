import React, { Component } from 'react';

class QuickLink extends Component {

  handleClick = (event) => {
    event.preventDefault();
    this.props.onClick(this.props.links);
  }

  render() {
    const {
      children
    } = this.props;

    return (
        <button onClick={this.handleClick} className="quickLinkButton">{children}</button>
    );
  }
}

export default QuickLink;