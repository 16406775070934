import React from 'react';
import classNames from 'classnames';
import Button from './Button';

const Sort = ({sortKey, activeSortKey, isSortReverse, onSort, elementID, children }) => {
  const sortClass = classNames(
    'button-inline',
    { 'button-active': sortKey === activeSortKey},
    { 'fa fa-sort-asc': sortKey === activeSortKey && isSortReverse},
    { 'fa fa-sort-desc': sortKey === activeSortKey && !isSortReverse}
  );

  return(
    <Button onClick={() => onSort(sortKey)} className={sortClass} classID={elementID}>
      {children}
    </Button>
  );
}

export default Sort;