import React, {useContext} from 'react';
import Button from '../components/Button';
import ConfigContext from "../components/ConfigContext";

let ConfigButton = ({ elementID = '' }) => {
    const { configToggle, setConfigToggle } = useContext(ConfigContext);
    return (
        <Button onClick={ () => setConfigToggle(configToggle) } className="button-config" classID={elementID}/>
    )
}

export default ConfigButton;