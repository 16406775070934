import React, { Component } from 'react';
import dateFormat from 'dateformat';
import { Row, Col } from 'react-bootstrap';
import { sortBy } from 'lodash';
import Sort from './Sort';
import ConfigButton from '../containers/ConfigButton';

const SORTS = {
  NONE: list => list,
  SOURCE: list => sortBy(list, 'source'),
  TITLE: list => sortBy(list, 'title'),
  AUTHOR: list => sortBy(list, 'author'),
  COMMENTS: list => sortBy(list, 'num_comments').reverse(),
  SCORE: list => sortBy(list, 'score').reverse(),
  SCORE_NORM: list => sortBy(list, 'normalised_score').reverse(),
  DATE: list => sortBy(list, 'created_at').reverse(),
};


class Table extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //sortKey: 'SCORE_NORM',
      sortKey: 'NONE',
      isSortReverse: false,
    };

    this.onSort = this.onSort.bind(this);
  }

  onSort(sortKey) {
    const isSortReverse = this.state.sortKey === sortKey &&
      !this.state.isSortReverse;
    this.setState({ sortKey, isSortReverse });
  }

  render() {
    const {
      list
    } = this.props;

    const {
      sortKey,
      isSortReverse,
    } = this.state;


    const sortedList = SORTS[sortKey](list);
    const reverseSortedList = isSortReverse ? sortedList.reverse() : sortedList;

    return (
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Row className="table-header-border">
            <Col sm={4} md={4} lg={4} xsHidden={true} className="table-header">
              <Sort sortKey={'SCORE'} onSort={this.onSort} activeSortKey={sortKey} isSortReverse={isSortReverse} elementID={'sortByScore'}>
                S
              </Sort>
              &nbsp; / &nbsp;
              <Sort sortKey={'SCORE_NORM'} onSort={this.onSort} activeSortKey={sortKey} isSortReverse={isSortReverse} elementID={'sortByScoreNorm'}>
                S
              </Sort>
              &nbsp; / &nbsp;
              <Sort sortKey={'COMMENTS'} onSort={this.onSort} activeSortKey={sortKey} isSortReverse={isSortReverse} elementID={'sortByComments'}>
                C
              </Sort>
              &nbsp; / &nbsp;
              <Sort sortKey={'DATE'} onSort={this.onSort} activeSortKey={sortKey} isSortReverse={isSortReverse} elementID={'sortByDate'}>
                D
              </Sort>
            </Col>
            <Col sm={7} md={7} lg={7} xsHidden={true} className="table-header">
              <Sort sortKey={'SOURCE'} onSort={this.onSort} activeSortKey={sortKey} isSortReverse={isSortReverse} elementID={'sortBySource'}>
                Source
              </Sort>
              &nbsp; / &nbsp;
              <Sort sortKey={'TITLE'} onSort={this.onSort} activeSortKey={sortKey} isSortReverse={isSortReverse} elementID={'sortByTitle'}>
                Title
              </Sort>
              &nbsp; / &nbsp;
              <Sort sortKey={'AUTHOR'} onSort={this.onSort} activeSortKey={sortKey} isSortReverse={isSortReverse} elementID={'sortByAuthor'}>
                Author
              </Sort>
            </Col>

            <Col sm={1} md={1} lg={1} xsHidden={true} className="table-header">
              <ConfigButton elementID={'configButton'}/>
            </Col>
          </Row>


          {/*
           Small / Mobile Device
           */
          }
          <Row className="table-header-border">
            <Col xs={12} smHidden={true} mdHidden={true} lgHidden={true} className="table-header">
              <Sort sortKey={'SCORE'} onSort={this.onSort} activeSortKey={sortKey} isSortReverse={isSortReverse} elementID={'sortByScoreSmallDevice'}>
                S
              </Sort>
              &nbsp; / &nbsp;
              <Sort sortKey={'SCORE_NORM'} onSort={this.onSort} activeSortKey={sortKey} isSortReverse={isSortReverse} elementID={'sortByScoreNormSmallDevice'}>
                S
              </Sort>
              &nbsp; / &nbsp;
              <Sort sortKey={'COMMENTS'} onSort={this.onSort} activeSortKey={sortKey} isSortReverse={isSortReverse} elementID={'sortByCommentsSmallDevice'}>
                C
              </Sort>
              &nbsp; / &nbsp;
              <Sort sortKey={'DATE'} onSort={this.onSort} activeSortKey={sortKey} isSortReverse={isSortReverse} elementID={'sortByDateSmallDevice'}>
                D
              </Sort>
              &nbsp; / &nbsp;
              <Sort sortKey={'SOURCE'} onSort={this.onSort} activeSortKey={sortKey} isSortReverse={isSortReverse} elementID={'sortBySourceSmallDevice'}>
                Sour
              </Sort>
              &nbsp; / &nbsp;
              <Sort sortKey={'TITLE'} onSort={this.onSort} activeSortKey={sortKey} isSortReverse={isSortReverse} elementID={'sortByTitleSmallDevice'}>
                Titl
              </Sort>
              &nbsp; / &nbsp;
              <Sort sortKey={'AUTHOR'} onSort={this.onSort} activeSortKey={sortKey} isSortReverse={isSortReverse} elementID={'sortByAuthorSmallDevice'}>
                Auth
              </Sort>

              <ConfigButton/>
            </Col>
          </Row>

          {
            reverseSortedList.map(item =>
              <Row className="table-row" key={item.id}>
                <Col sm={12} md={12} lg={12} xs={12}>
                  <Row>
                    <Col sm={2} md={2} lg={2} xsHidden={true}>
                      <Row>
                        <Col sm={12} md={12} lg={12}>
                          s: {item.score} / {item.normalised_score}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={12} lg={12}>
                          &nbsp;
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={12} lg={12}>
                          &nbsp;
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={12} lg={12} xsHidden={true} className="lowImpactFont">
                          {dateFormat(item.created_at, "dd/mm/yy HH:MM")}
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={4} sm={2} md={2} lg={2}>
                      <a href={item.thumb_url}><img src={item.thumbnail} className="img" alt={item.source}/></a>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8} className="titleSubjectCol">
                      <a href={item.url}>{item.title}</a>
                      &nbsp;
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={2} md={2} lg={2} xsHidden={true} id="source" className="lowImpactFont">
                      {item.source_sub_chan}
                    </Col>
                    <Col sm={10} md={10} lg={10} xsHidden={true} className="lowImpactFont bold">
                      c: <a href={item.discussion_url}>{item.num_comments}</a>
                    </Col>
                  </Row>


                  <Row>
                    <Col sm={2} md={2} lg={2} xsHidden={true} id="source" className="lowImpactFont">
                      <a href={item.url}>{item.source}</a>
                    </Col>
                    <Col sm={10} md={10} lg={10} xsHidden={true} className="lowImpactFont bold">
                      {item.author}
                    </Col>
                  </Row>


                  {/*
                   Small / Mobile Device
                   */
                  }
                  <Row>
                    <Col xs={6} smHidden={true} mdHidden={true} lgHidden={true}>
                      s: {item.score} / {item.normalised_score}
                    </Col>
                    <Col xs={6} smHidden={true} mdHidden={true} lgHidden={true} className="lowImpactFont bold">
                      c: <a href={item.discussion_url}>{item.num_comments}</a>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} smHidden={true} mdHidden={true} lgHidden={true} className="lowImpactFont">
                      {item.source_sub_chan}
                    </Col>
                    <Col xs={6} smHidden={true} mdHidden={true} lgHidden={true} className="lowImpactFont bold">
                      {item.author}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} smHidden={true} mdHidden={true} lgHidden={true} id="source" className="lowImpactFont">
                      <a href={item.url}>{item.source}</a>
                    </Col>
                    <Col xs={6} smHidden={true} mdHidden={true} lgHidden={true} className="lowImpactFont">
                      {dateFormat(item.created_at, "dd/mm HH:MM")}
                    </Col>
                  </Row>
                </Col>
              </Row>
            )
          }
        </Col>
      </Row>
    );
  }
}

export default Table;