import log from "loglevel";

class ConfigData {
  constructor() {
    log.debug('ConfigData:constructor() ');
    this.buildConfigDataStructure = this.buildConfigDataStructure.bind(this);
  }

  buildConfigDataStructure(config) {
    log.debug('buildConfigDataStructure()', JSON.stringify(config));

    const configValue = [
      {
        serviceClient: "reddit",
        enabled: config.redditConfig_enabled,
        subReddit: config.redditConfig_subReddit
      },
      {
        serviceClient: "hackerNews",
        enabled: config.hackerNewsConfig_enabled,
        tags: config.hackerNewsConfig_tags
      },
      {
        serviceClient: "fourChan",
        enabled: config.fourChanConfig_enabled,
        board: config.fourChanConfig_board
      },
      {
        serviceClient: "bezAgg",
        enabled: config.bezAggConfig_enabled,
        link_target: config.bezAggConfig_link_target
      }
    ];

    return configValue;
  }
}

export default ConfigData;