import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import log from 'loglevel';
import './App.css';

import Table from './components/Table';
import ButtonWithLoading from './components/ButtonWithLoading';
import Config from './components/Config';
import ConfigContext from './components/ConfigContext'
import {CONFIG_HIDE, toggleConfig, bezaggDefaultConfig} from './components/ConfigContextTypes'
import ConfigClient from "./utils/ConfigClient";

log.setLevel(log.levels.DEBUG);
//log.enableAll(); / log.disableAll();
const DEFAULT_PAGE=0;
const LIST_URL = process.env.REACT_APP_BEZAGGSERVER;

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      results: null,
      isLoading: false,
      configToggle: CONFIG_HIDE,
      bezAggConfig: bezaggDefaultConfig,
    };

    this.setSearchTopstories = this.setSearchTopstories.bind(this);
    this.fetchSearchTopstories = this.fetchSearchTopstories.bind(this);
    this.buildServerEndpointURL = this.buildServerEndpointURL.bind(this);
    this.fetchFromServer = this.fetchFromServer.bind(this);
    this.setConfigToggle = this.setConfigToggle.bind(this);
    this.setBezaggConfig = this.setBezaggConfig.bind(this);
    this.setConfig = this.setConfig.bind(this);

    log.debug('App:constructor() ', JSON.stringify(props));
  }

  setConfigToggle = (config) => {
    this.setState((prevState) => ({ configToggle: toggleConfig(config) }))
  }

  setBezaggConfig = (bezAggConfig) => {
    this.setState((prevState) => ({ bezAggConfig: bezAggConfig }))
  }

  componentDidMount() {
    log.debug('App:componentDidMount() ');

    const configClient = new ConfigClient();
    configClient.fetchConfig()
      .then(config => this.setConfig(config))
      .then(() => this.fetchSearchTopstories(DEFAULT_PAGE));
  }

  setSearchTopstories(result) {
    console.time('App:setSearchTopstories');
    log.trace('App:setSearchTopstories() ' + JSON.stringify(result));

    const {hits, page} = result;
    const {results} = this.state;
    const oldHits = results && results.hits ? results.hits : [];

    const updatedHits = [
      ...oldHits,
      ...hits
    ];

    this.setState({
      results: {
        hits: updatedHits,
        page: page
      },
      isLoading: false
    });
    console.timeEnd('App:setSearchTopstories');
  }

  fetchSearchTopstories(page) {
    log.debug('App:fetchSearchTopstories()');

    this.setState({ isLoading: true});


    let listServerURL = this.buildServerEndpointURL();
    const listURLWithParams = `${listServerURL}/${page}`;
    this.fetchFromServer(listURLWithParams)
      .then(response => response.json())
      .then(result => this.setSearchTopstories(result));
  }

  buildServerEndpointURL() {
    //For functional selenium testing using JSON-test-server. Ideally this would be IoC injected.
    if (process.env.REACT_APP_E2E_TEST_MODE === 'true') {
        return process.env.REACT_APP_E2E_BEZAGGSERVER +  "/list";
    }
    return LIST_URL;
  }

  fetchFromServer(url) {
    const { bezAggConfig } = this.state;
    log.debug('App:fetchFromServer()', JSON.stringify(bezAggConfig));

    var bezaggsearch = '';
    const seperator = ','
    if (bezAggConfig.redditConfig_enabled) bezaggsearch += "reddit=" + bezAggConfig.redditConfig_subReddit + seperator;
    if (bezAggConfig.hackerNewsConfig_enabled) bezaggsearch += "hackernews=" + bezAggConfig.hackerNewsConfig_tags + seperator;
    if (bezAggConfig.fourChanConfig_enabled) bezaggsearch += "4chan=" + bezAggConfig.fourChanConfig_board + seperator;

    log.debug('App:fetchFromServer() bezAggConfig cache headers: ' + bezaggsearch);
    return fetch(url, {
          method: 'GET',
          headers: {
            bezaggsearch: bezaggsearch
          },
          credentials: 'include'
    });
  }



  setConfig(config, setStateCallback) {
    console.time('App:setConfig');
    log.debug('App:setConfig() ', JSON.stringify(config));

    const configArray = config.data;
    const redditConfig = configArray.find(x => x.serviceClient === "reddit");
    const hackerNewsConfig = configArray.find(x => x.serviceClient === "hackerNews");
    const fourChanConfig = configArray.find(x => x.serviceClient === "fourChan");
    const bezAggConfig = configArray.find(x => x.serviceClient === "bezAgg");

    const formattedConfig = {
      redditConfig_enabled: redditConfig.enabled,
      redditConfig_subReddit: redditConfig.subReddit,

      hackerNewsConfig_enabled: hackerNewsConfig.enabled,
      hackerNewsConfig_tags: hackerNewsConfig.tags,

      fourChanConfig_enabled: fourChanConfig.enabled,
      fourChanConfig_board: fourChanConfig.board,

      bezAggConfig_enabled: bezAggConfig.enabled,
      bezAggConfig_link_target: bezAggConfig.link_target
    };

    this.setBezaggConfig(formattedConfig);
    //const { setBezaggConfig } = this.context
    //setBezaggConfig(formattedConfig);

    console.timeEnd('App:setConfig');
  }


  render() {
    const {results, isLoading} = this.state;
    const page = (results && results.page) || 0;
    const list = (results && results.hits) || [];

    const { configToggle } = this.state;
    const { setConfigToggle } = this;

    const { bezAggConfig } = this.state;
    const { setBezaggConfig } = this;

    return (
      <ConfigContext.Provider value={{ configToggle: configToggle,
                                        setConfigToggle,
                                        bezAggConfig: bezAggConfig,
                                        setBezaggConfig,
          }}>
        <Grid>

          <Config/>

          <Row>
            <Col xs={12} sm={12} md={12} lg={12} className="pageNo">
              {
                bezAggConfig.redditConfig_enabled &&
               <span> <b> Reddits: </b> {bezAggConfig.redditConfig_subReddit} </span>
              }
              { bezAggConfig.redditConfig_enabled && bezAggConfig.hackerNewsConfig_enabled ? <span>|</span> : null }
              {
                bezAggConfig.hackerNewsConfig_enabled &&
               <span> <b> Hacker News: </b> {bezAggConfig.hackerNewsConfig_tags} </span>
              }
              { (bezAggConfig.hackerNewsConfig_enabled && bezAggConfig.fourChanConfig_enabled) ||
                (bezAggConfig.redditConfig_enabled && !bezAggConfig.hackerNewsConfig_enabled && bezAggConfig.fourChanConfig_enabled) ?
                <span>|</span> : null
              }
              {
                bezAggConfig.fourChanConfig_enabled &&
                <span> <b> 4Chan: </b> {bezAggConfig.fourChanConfig_board} </span>
              }
            </Col>
          </Row>

          <Table list={list}/>

          <Row>
            <Col xs={12} sm={12} md={12} lg={12} className="pageNo">
              Page: {page}
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={12} md={12} lg={12} className="interactions">
              <ButtonWithLoading
                isLoading={isLoading}
                onClick={() => this.fetchSearchTopstories(+page + 1)}>
                More
              </ButtonWithLoading>
            </Col>
          </Row>

        </Grid>
      </ConfigContext.Provider>
    );
  }
}

export default App;