import log from "loglevel";


const CONFIG_ENDPOINT = '/config';
const CONFIG_URL = process.env.REACT_APP_BEZAGGSERVER + CONFIG_ENDPOINT;

class ConfigClient {
  constructor() {
    log.debug('ConfigClient:constructor() ');
    this.postToServer = this.postToServer.bind(this);
    this.fetchFromServer = this.fetchFromServer.bind(this);
    this.fetchConfig = this.fetchConfig.bind(this);
  }

  postToServer(configData) {
    log.debug('ConfigClient:postToServer()', JSON.stringify(configData));

    let configServerURL = ConfigClient.buildServerEndpointURL();
    this.fetchFromServer(configServerURL, configData)
      .then( response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok.');
      }).then(json => {
      if (json.status === 'updated') {
        log.debug('ConfigClient:config successfully updated, redirecting and reloading', JSON.stringify(json));
        window.location.href= "/";
      } else {
        log.error('ConfigClient:config update error', JSON.stringify(json));
        //window.alert("errors: " + JSON.stringify(json));
        //TODO: show error to user via React notification system at top of window (ie flux)
      }
    }).catch(error => {
      log.error('ConfigClient:There has been a problem with your fetch operation: ', error.message);
      //window.alert("errors: " + error.message);
      //TODO: show error to user via React notification system at top of window (ie flux)
    });

  }

  static buildServerEndpointURL() {
    //For functional selenium testing using JSON-test-server. Ideally this would be IoC injected.
    if (process.env.REACT_APP_E2E_TEST_MODE === 'true') {
      return process.env.REACT_APP_E2E_BEZAGGSERVER +  CONFIG_ENDPOINT;
    }
    return CONFIG_URL;
  }

  fetchFromServer(url, configData) {
    log.debug('ConfigClient:fetchFromServer(): ' + url, JSON.stringify(configData));
    return fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({data: configData})
    });
  }

  fetchConfig() {
    log.debug('ConfigClient:fetchConfig()');

    let configServerURL = ConfigClient.buildServerEndpointURL();
    return fetch(configServerURL, {
      method: 'GET',
      credentials: 'include'
    }).then(response => response.json())
  }

}

export default ConfigClient;
export const CONFIG_URL_ENDPOINT = ConfigClient.buildServerEndpointURL();