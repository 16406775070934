import { createContext } from 'react';
import {CONFIG_HIDE, bezaggDefaultConfig} from "./ConfigContextTypes";

/*
Setup ConfigContext with default values
 */
const ConfigContext = createContext({
  configToggle: CONFIG_HIDE,
  setConfigToggle: (configToggle) => { },
  bezAggConfig: bezaggDefaultConfig,
  setBezAggConfig: (bezAggConfig) => { },
});
export default ConfigContext;