import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import validator from 'validator';
import log from 'loglevel';
import _ from 'underscore';


import QuickLink from './QuickLink';
import { CONFIG_SHOW } from './ConfigContextTypes';
import ConfigContext from "./ConfigContext";

import ConfigData from "../utils/ConfigData";
import ConfigClient from "../utils/ConfigClient";
import { CONFIG_URL_ENDPOINT } from "../utils/ConfigClient";

log.setLevel(log.levels.DEBUG);
//log.enableAll(); / log.disableAll();


class Config extends Component {
  static contextType = ConfigContext;

  constructor(props) {
    super(props);
    this.state = {
      isFormSubmitEnabled: true,
    };
    
    this.onConfigChange = this.onConfigChange.bind(this);
    this.onConfigSubmit = this.onConfigSubmit.bind(this);
    this.onQuickLinkClick = this.onQuickLinkClick.bind(this);

    this.applyBusinessRules = this.applyBusinessRules.bind(this);
    this.validateConfig = this.validateConfig.bind(this);

    log.debug('Config:constructor() ', JSON.stringify(props));
  }

  componentDidMount() {
    log.debug('Config:componentDidMount() ');

  }
  
  onConfigChange(event) {
    console.time('Config:onConfigChange');
    log.debug('Config:onConfigChange()');

    const { bezAggConfig, setBezaggConfig } = this.context

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    log.debug(`Config:onConfigChangeValue(): ${target.name} [${target.type}] = ${target.value} / ${target.checked}`);

    var updatedConfig = {...bezAggConfig, [target.name]: value};
    this.applyBusinessRules(updatedConfig);

    var configValid = this.validateConfig(updatedConfig);
    log.debug(`configValid(): ${configValid}`, updatedConfig);

    this.setState({ isFormSubmitEnabled: configValid });
    setBezaggConfig(updatedConfig);

    console.timeEnd('Config:onConfigChange');
  }

  onConfigSubmit(event) {
    log.debug('Config:onConfigSubmit()');
    event.preventDefault();

    const { bezAggConfig } = this.context
    log.debug('Config:onConfigSubmit() - config', JSON.stringify(bezAggConfig));

    const configData = new ConfigData().buildConfigDataStructure(bezAggConfig);
    const configClient = new ConfigClient();
    configClient.postToServer(configData);

    return false;
  }

  onQuickLinkClick(linksConfig) {
    log.debug('Config:onQuickLinkClick', JSON.stringify(linksConfig));
    //this.setConfig(linksConfig, this.onConfigSubmitEmptyEvent);

    const configData = new ConfigData().buildConfigDataStructure(linksConfig);
    const configClient = new ConfigClient();
    configClient.postToServer(configData);

    return false;
  }

  applyBusinessRules(config) {
    if (!config.redditConfig_enabled) {
      config.redditConfig_subReddit = "";
    }

    if (!config.hackerNewsConfig_enabled) {
      config.hackerNewsConfig_tags = "";
    }

    if (!config.fourChanConfig_enabled) {
      config.fourChanConfig_board = "";
    }

    if (!config.bezAggConfig_enabled) {
      config.bezAggConfig_link_target = "";
    }
  }

  validateConfig(config) {
    log.debug('Config:validateConfig() ');
    var isRedditConfigValid = true;
    if (config.redditConfig_enabled) {
      const validSubRedditRegExMatcher = /^[A-Za-z0-9_.+]*$/;

      isRedditConfigValid =
        !validator.isEmpty (config.redditConfig_subReddit) &&
        !validator.contains(config.redditConfig_subReddit, ' ') &&
        validSubRedditRegExMatcher.test(config.redditConfig_subReddit);
      log.debug('Config:validateConfig() - isRedditConfigValid: ' + isRedditConfigValid);
    }

    var isHackerNewsConfigValid = true;
    if (config.hackerNewsConfig_enabled) {
      const validHackNewsTags = [
        "front_page",
        "show_hn",
        "ask_hn",
        "poll"
      ];

      isHackerNewsConfigValid =
        !validator.isEmpty (config.hackerNewsConfig_tags) &&
        !validator.contains(config.hackerNewsConfig_tags, ' ') &&
        _.contains(validHackNewsTags, config.hackerNewsConfig_tags.toLowerCase(), );
      log.debug('Config:validateConfig() - isHackerNewsConfigValid: ' + isHackerNewsConfigValid);
    }

    var isFourChanConfigValid = true;
    if (config.fourChanConfig_enabled) {
      const validFourChanBoards = [
        "a","b","c","d","e","f","g","gif","h","hr","k","m","o","p","r",
        "s","t","u","v","vg","vr","w","wg","i","ic","r9k","s4s","vip","qa",
        "cm","hm","lgbt","y","3","aco","adv","an","asp","bant","biz","cgl","ck",
        "co","diy","fa","fit","gd","hc","his","int","jp","lit","mlp","mu","n","news",
        "out","po","pol","qst","sci","soc","sp","tg","toy","trv","tv","vp","wsg","wsr","x"
      ];

      isFourChanConfigValid =
        !validator.isEmpty (config.fourChanConfig_board) &&
        !validator.contains(config.fourChanConfig_board, ' ') &&
        _.contains(validFourChanBoards, config.fourChanConfig_board.toLowerCase());
      log.debug('Config:validateConfig() - isFourChanConfigValid: ' + isFourChanConfigValid);
    }

    const anySourceSelected = config.redditConfig_enabled ||
                              config.hackerNewsConfig_enabled ||
                              config.fourChanConfig_enabled;


    return isRedditConfigValid &&
           isHackerNewsConfigValid &&
           isFourChanConfigValid &&
           anySourceSelected;
  }


  render() {
    const {
      isFormSubmitEnabled
    } = this.state;

    const { configToggle, bezAggConfig } = this.context;
    const config = bezAggConfig;

    return (
      <Row className="table">
        <Col xs={11} sm={11} md={11} lg={11}>
          {
            configToggle === CONFIG_SHOW
              ?
              <form action={CONFIG_URL_ENDPOINT} method="post" encType="application/x-www-form-urlencoded" onSubmit={this.onConfigSubmit}>
                <Row>
                  <Col xs={3} sm={3} md={3} lg={3}>
                    <input type="checkbox" id="redditConfig_enabled" name="redditConfig_enabled" checked={config.redditConfig_enabled} onChange={this.onConfigChange}/>
                    &nbsp;
                    <label htmlFor="redditConfig_subReddit">Reddits</label>
                  </Col>
                  <Col xs={9} sm={9} md={9} lg={9} className="inputTextDescription">
                    {
                      <div>
                        <input type="text" id="redditConfig_subReddit" name="redditConfig_subReddit"
                               value={config.redditConfig_subReddit} onChange={this.onConfigChange}
                               disabled={!config.redditConfig_enabled} className="inputBoxSmall"/>
                        <span className={config.redditConfig_enabled ? "": "descriptionText-disabled"}> all, AskReddit, worldnews, funny+jokes+videos </span>
                      </div>
                    }
                  </Col>
                </Row>
                <Row>
                  <Col xs={3} sm={3} md={3} lg={3}>
                    <input type="checkbox" id="hackerNewsConfig_enabled" name="hackerNewsConfig_enabled" checked={config.hackerNewsConfig_enabled} onChange={this.onConfigChange}/>
                    &nbsp;
                    <label htmlFor="hackerNewsConfig_tags">Hacker News Tags</label>
                  </Col>
                  <Col xs={9} sm={9} md={9} lg={9} className="inputTextDescription">
                        <div>
                          <input type="text" id="hackerNewsConfig_tags" name="hackerNewsConfig_tags" value={config.hackerNewsConfig_tags} onChange={this.onConfigChange}
                                                                        disabled={!config.hackerNewsConfig_enabled} className="inputBoxSmall"/>
                          <span className={config.hackerNewsConfig_enabled ? "": "descriptionText-disabled"}> front_page, show_hn, ask_hn, poll </span>
                        </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3} sm={3} md={3} lg={3}>
                    <input type="checkbox" id="fourChanConfig_enabled" name="fourChanConfig_enabled" checked={config.fourChanConfig_enabled} onChange={this.onConfigChange}/>
                    &nbsp;
                    <label htmlFor="fourChanConfig_board">4Chan Board</label>
                  </Col>
                  <Col xs={9} sm={9} md={9} lg={9} className="inputTextDescription">
                        <div>
                          <input type="text" id="fourChanConfig_board" name="fourChanConfig_board" value={config.fourChanConfig_board} onChange={this.onConfigChange}
                                                                       disabled={!config.fourChanConfig_enabled} className="inputBoxSmall"/>
                          <span className={config.fourChanConfig_enabled ? "": "descriptionText-disabled"}> pol, g, v, vg, a, b, sp, i </span>
                        </div>
                  </Col>
                </Row>
                {/*
                 <Row>
                 <Col xs={3} sm={3} md={3} lg={3}>
                 &nbsp;
                 &nbsp;
                 &nbsp;
                 <label htmlFor="bezAggConfig_link_target">BezAgg</label>
                 </Col>
                 <Col xs={9} sm={9} md={9} lg={9} className="inputTextDescription">
                 <input type="text" id="bezAggConfig_link_target" name="bezAggConfig_link_target" value={config.bezAggConfig_link_target} onChange={this.onConfigChange} className="inputBoxSmall"/>
                 open in: '_self' (this window) or '_blank' (new window)
                 </Col>
                 </Row>
                 */}

                <Row className="rowPaddingVertical20">
                  <Col xs={3} sm={3} md={3} lg={3}>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <label>Quick links</label>
                  </Col>
                  <Col xs={9} sm={9} md={9} lg={9} className="inputTextDescription">

                    <Row className="rowPaddingVertical10">
                      <Col xs={12} sm={12} md={12} lg={12}>

                        <QuickLink links={
                          {
                            redditConfig_enabled: true,
                            redditConfig_subReddit: "programming+java+javascript+webdev",
                            hackerNewsConfig_enabled: false,
                            hackerNewsConfig_tags: "",
                            fourChanConfig_enabled: false,
                            fourChanConfig_board: "",
                            bezAggConfig_enabled: true,
                            bezAggConfig_link_target: ""
                          }
                        } onClick={this.onQuickLinkClick}>
                          r/programming + r/java + r/javascript + r/webdev
                        </QuickLink>

                        &nbsp; | &nbsp;

                        <QuickLink links={
                            {
                              redditConfig_enabled: true,
                              redditConfig_subReddit: "technology",
                              hackerNewsConfig_enabled: true,
                              hackerNewsConfig_tags: "front_page",
                              fourChanConfig_enabled: true,
                              fourChanConfig_board: "g",
                              bezAggConfig_enabled: true,
                              bezAggConfig_link_target: ""
                            }
                        } onClick={this.onQuickLinkClick}>
                          r/technology + hackernews + g
                        </QuickLink>

                      </Col>
                    </Row>
                    <Row className="rowPaddingVertical10">
                      <Col xs={12} sm={12} md={12} lg={12}>

                        <QuickLink links={
                            {
                              redditConfig_enabled: true,
                              redditConfig_subReddit: "all",
                              hackerNewsConfig_enabled: true,
                              hackerNewsConfig_tags: "front_page",
                              fourChanConfig_enabled: true,
                              fourChanConfig_board: "pol",
                              bezAggConfig_enabled: true,
                              bezAggConfig_link_target: ""
                            }
                        } onClick={this.onQuickLinkClick}>
                          r/all + hackernews + pol
                        </QuickLink>

                        &nbsp; | &nbsp;

                        <QuickLink links={
                            {
                              redditConfig_enabled: true,
                              redditConfig_subReddit: "conspiracy",
                              hackerNewsConfig_enabled: false,
                              hackerNewsConfig_tags: "",
                              fourChanConfig_enabled: true,
                              fourChanConfig_board: "x",
                              bezAggConfig_enabled: true,
                              bezAggConfig_link_target: ""
                            }
                        } onClick={this.onQuickLinkClick}>
                          r/conspiracy + x
                        </QuickLink>


                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="rowPaddingVertical20">
                  <Col xs={3} sm={3} md={3} lg={3}>
                    &nbsp;
                  </Col>
                  <Col xs={3} sm={3} md={3} lg={3}>
                    <button type="submit" disabled={!isFormSubmitEnabled}
                                          className={isFormSubmitEnabled ? "button-submit": "button-submit-disabled"}>
                      Update
                    </button>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    &nbsp;
                  </Col>
                </Row>
              </form>
              :
              null
          }

        </Col>
        <Col xs={1} sm={1} md={1} lg={1}>
          &nbsp;
        </Col>
      </Row>
    );
  }
}

export default Config;