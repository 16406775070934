const CONFIG_HIDE = "hide";
exports.CONFIG_HIDE = CONFIG_HIDE;
const CONFIG_SHOW = "show";
exports.CONFIG_SHOW = CONFIG_SHOW;

const toggleConfig = (configToggle) => {
  if (typeof configToggle === 'undefined') {
    return CONFIG_HIDE;
  }

  if (configToggle === CONFIG_SHOW) {
    return CONFIG_HIDE;
  } else if (configToggle === CONFIG_HIDE) {
    return CONFIG_SHOW;
  }

  return configToggle;
}
exports.toggleConfig = toggleConfig;

const BEZAGG_DEFAULT_CONFIG = {
  redditConfig_enabled: false,
  redditConfig_subReddit: '',

  hackerNewsConfig_enabled: false,
  hackerNewsConfig_tags: '',

  fourChanConfig_enabled: false,
  fourChanConfig_board: '',

  bezAggConfig_enabled: false,
  bezAggConfig_link_target: '',
}
exports.bezaggDefaultConfig = BEZAGG_DEFAULT_CONFIG;
