import React from 'react';
import Button from './Button';

const Loading = () =>
  <div>
    <i className="fa fa-spinner fa-pulse fa-3x fa-fw" id="loadingSpinner"></i>
    <span className="sr-only">Loading...</span>
  </div>

const withLoading = (Component) => ({ isLoading, ...rest}) =>
  isLoading ? <Loading /> : <Component { ...rest} />

const ButtonWithLoading = withLoading(Button);

export default ButtonWithLoading;